"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const gsap_1 = require("gsap");
function navMobileInit() {
    var _a;
    const headerEl = document.querySelector(".header.--below-895");
    const burgerBtn = document.querySelector(".burger");
    const navMobileWrapper = document.querySelector(".nav-mobile-wrapper");
    const chevronDown = document.querySelector(".chevron-black");
    const scrollableNavList = document.querySelector(".--scrollable");
    chevronDown === null || chevronDown === void 0 ? void 0 : chevronDown.addEventListener("click", () => {
        if (scrollableNavList) {
            scrollableNavList.scrollTo({
                top: scrollableNavList.scrollHeight,
                behavior: "smooth",
            });
        }
    });
    scrollableNavList === null || scrollableNavList === void 0 ? void 0 : scrollableNavList.addEventListener("scroll", () => {
        const atBottom = scrollableNavList.scrollHeight - scrollableNavList.scrollTop === scrollableNavList.clientHeight;
        const aboveBottom = scrollableNavList.scrollHeight - scrollableNavList.scrollTop < scrollableNavList.clientHeight + 50; // Ajustá los píxeles a tu necesidad
        if (atBottom) {
            gsap_1.gsap.to(chevronDown, {
                opacity: 0,
                duration: 0.5,
                ease: "power2.out",
                onComplete: () => {
                    chevronDown.style.display = "none";
                },
            });
        }
        else if (!atBottom && !aboveBottom) {
            chevronDown.style.display = "block";
            gsap_1.gsap.to(chevronDown, { opacity: 1, duration: 0.5, ease: "power2.out" });
        }
    });
    let isDesktop = window.matchMedia("(min-width: 895px)");
    const headerElHeight = headerEl.offsetHeight;
    if (burgerBtn === null || navMobileWrapper === null) {
        console.error("No burger btn found or nav mobile wrapper");
        return;
    }
    if (window.pageYOffset >= 100) {
        headerEl.classList.add("--hovered");
    }
    gsap_1.gsap.set(navMobileWrapper, { height: 0, opacity: 0, visibility: "hidden" });
    // Create a timeline with paused state
    const tl = gsap_1.gsap.timeline({ paused: true });
    // Add animations to the timeline
    tl.to(navMobileWrapper, {
        duration: 0.3,
        height: "calc(100dvh - 57px)",
        opacity: 1,
        visibility: "visible",
    }).to(headerEl, {
        duration: 0.3,
        // height: "calc(100vh - 32px)",
        height: "100vh",
    }, "<"); // "<" means start this animation at the same time as the previous one
    burgerBtn.addEventListener("click", () => {
        // Check if the nav is open and play or reverse the timeline
        if (headerEl.classList.contains("--open")) {
            tl.reverse();
            if (window.pageYOffset <= 100) {
                headerEl.classList.remove("--hovered");
            }
        }
        else {
            tl.play();
            headerEl.classList.add("--hovered");
        }
        headerEl.classList.toggle("--open");
        burgerBtn.classList.toggle("--active");
        document.body.classList.toggle("--no-scroll");
    });
    isDesktop.onchange = (e) => {
        if (e.matches && headerEl.classList.contains("--open")) {
            tl.reverse();
            headerEl.classList.toggle("--open");
            burgerBtn.classList.toggle("--active");
        }
    };
    // tabs
    const tabs = document.querySelector("[data-el='tabs']");
    const targets = (_a = tabs.querySelector("[data-el='tabs-block-container']")) === null || _a === void 0 ? void 0 : _a.querySelectorAll("[data-el='tab-option']");
    const contents = tabs.querySelectorAll("[data-el='tab-content']");
    const tabContainerEl = tabs.querySelector("[data-el='tab-content-container']");
    targets === null || targets === void 0 ? void 0 : targets.forEach((element) => {
        const target = element; // Type assertion to HTMLElement
        target.addEventListener("click", () => {
            const dataId = target.dataset.id;
            // Toggle active class for tabs
            targets.forEach((_element) => {
                const _target = _element; // Type assertion to HTMLElement
                _target.classList.toggle("--active", _target.dataset.id === dataId);
            });
            // Show/Hide content based on matching data id
            contents.forEach((contentElement) => {
                const content = contentElement; // Type assertion to HTMLElement
                if (content.dataset.ref === dataId) {
                    content.classList.add("--show");
                }
                else {
                    content.classList.remove("--show");
                }
            });
            gsap_1.gsap.fromTo(tabContainerEl, { opacity: 0 }, { opacity: 1, duration: 0.8 });
        });
    });
    let lastScrollTop = 0;
    window.addEventListener("scroll", () => {
        let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScroll > lastScrollTop) {
            // Scroll Down
            gsap_1.gsap.to(headerEl, { top: -headerElHeight, duration: 0.3 });
        }
        else {
            // Scroll Up
            gsap_1.gsap.to(headerEl, { top: 0, duration: 0.3 });
            headerEl.classList.add("--hovered");
        }
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
        if (currentScroll <= 100) {
            headerEl.classList.remove("--hovered");
        }
        if (currentScroll >= 100) {
            headerEl.classList.add("--hovered");
        }
    }, false);
}
exports.default = navMobileInit;
function isOverflowing(element) {
    return element.scrollHeight > element.clientHeight;
}
function isScrollable(element) {
    return isOverflowing(element) && ["scroll", "auto"].includes(window.getComputedStyle(element).overflowY);
}
