"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setCVTargetBlank = void 0;
const setCVTargetBlank = () => {
    const aTags = document.querySelectorAll('.upload-cv a');
    if (aTags.length) {
        aTags.forEach((anchor) => {
            anchor.target = "_blank";
        });
    }
};
exports.setCVTargetBlank = setCVTargetBlank;
