"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const gsap_1 = require("gsap");
function tabsInit() {
    var _a, _b, _c, _d;
    const tabs = document.querySelector(".tabs:not(.--react)");
    if (!tabs) {
        console.error("No tabs found");
        return;
    }
    let targets = tabs === null || tabs === void 0 ? void 0 : tabs.querySelectorAll(".tabs-block li");
    let articles = tabs === null || tabs === void 0 ? void 0 : tabs.querySelectorAll(".article");
    let activeTab = 0;
    let old = 0;
    let heights = [];
    // let dur = 0.4;
    let animation;
    for (let i = 0; i < targets.length; i++) {
        // @ts-ignore
        targets[i].index = i;
        // if article has carousel add 10% to height to not crop arrows
        if ((_a = articles[i]) === null || _a === void 0 ? void 0 : _a.querySelector(".carousel")) {
            // @ts-ignore
            heights.push(((_b = articles[i]) === null || _b === void 0 ? void 0 : _b.offsetHeight) + ((_c = articles[i]) === null || _c === void 0 ? void 0 : _c.offsetHeight) * 0.1);
        }
        else {
            // @ts-ignore
            heights.push((_d = articles[i]) === null || _d === void 0 ? void 0 : _d.offsetHeight);
        }
        if (articles[i]) {
            gsap_1.gsap.set(articles[i], { autoAlpha: 0, y: 20 }); // set all articles invisible
        }
        targets[i].addEventListener("click", doCoolStuff);
    }
    // TABS
    // set initial article and position bubble slider on first tab
    gsap_1.gsap.set(articles[0], { autoAlpha: 1, y: 0 });
    gsap_1.gsap.set(".slider", { x: targets[0].offsetLeft, width: targets[0].offsetWidth });
    gsap_1.gsap.set(".article-block", { height: heights[0] });
    // CONTENT ANIMATION
    function doCoolStuff() {
        // @ts-ignore
        if (this.index !== activeTab) {
            // If there's an animation in-progress, jump to the end immediately
            if (animation && animation.isActive()) {
                animation.progress(1);
            }
            animation = gsap_1.gsap.timeline({ defaults: { duration: 0.4 } });
            old = activeTab;
            // @ts-ignore
            activeTab = this.index;
            // Animate bubble slider to clicked target
            animation.to(".slider", { x: targets[activeTab].offsetLeft, width: targets[activeTab].offsetWidth });
            // Change class for old and new tab targets for color
            targets[old].classList.remove("--tab-active");
            targets[activeTab].classList.toggle("--tab-active");
            // Hide current tab-content
            animation.to(articles[old], { y: 20, autoAlpha: 0, duration: 0.5 }, 0);
            // Resize article block to accommodate new content
            animation.to(".article-block", { height: heights[activeTab] });
            // Show new tab-content
            animation.to(articles[activeTab], { y: 0, autoAlpha: 1, duration: 0.5 }, "-=0.25");
        }
    }
}
exports.default = tabsInit;
