"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function accordionInit() {
    const visibleParts = document.querySelectorAll(".accordion__visible-part");
    visibleParts.forEach((visiblePart) => {
        visiblePart.addEventListener("click", () => {
            const panel = visiblePart.querySelector(".panel");
            const arrow = visiblePart.querySelector(".chevron");
            // Close all other items
            visibleParts.forEach((otherVisiblePart) => {
                if (otherVisiblePart !== visiblePart) {
                    const otherPanel = otherVisiblePart.querySelector(".panel");
                    const otherArrow = otherVisiblePart.querySelector(".chevron");
                    otherPanel.style.maxHeight = "";
                    otherArrow.style.transform = "rotate(0deg)";
                    otherVisiblePart.classList.remove("--active");
                    otherArrow.classList.remove("active");
                }
            });
            // Toggle the current item
            if (panel.style.maxHeight) {
                panel.style.maxHeight = "";
                arrow.style.transform = "rotate(0deg)";
                visiblePart.classList.remove("--active");
                arrow.classList.remove("active");
            }
            else {
                panel.style.maxHeight = panel.scrollHeight + "px";
                arrow.style.transform = "rotate(180deg)";
                visiblePart.classList.add("--active");
                arrow.classList.add("active");
            }
        });
    });
}
exports.default = accordionInit;
