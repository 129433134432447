"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function openRedirectModalForDropdown(url) {
    const modalsEls = document.querySelectorAll(".modal[data-modal='uruguay-modal']");
    modalsEls.forEach((modal) => {
        const modalLinkEls = modal.querySelectorAll(".--modal-link");
        const closeModal = modal.querySelector(".modal-close-button");
        const contentWrapper = modal.querySelector(".content-wrapper");
        const imageWrapper = modal.querySelector(".image-wrapper");
        const closeEls = [closeModal];
        modalLinkEls.forEach((btn) => {
            closeEls.push(btn);
            btn.href = url;
            btn.target = "_self";
        });
        closeEls.forEach((el) => {
            el.addEventListener("click", () => {
                modal.setAttribute("closing", "");
                modal.addEventListener("animationend", () => {
                    modal.removeAttribute("closing");
                    modal.close();
                }, { once: true });
            });
        });
        // Agregar listener al backdrop
        modal.addEventListener("click", (event) => {
            if (!contentWrapper.contains(event.target) &&
                !imageWrapper.contains(event.target) &&
                !closeModal.contains(event.target)) {
                modal.setAttribute("closing", "");
                modal.addEventListener("animationend", () => {
                    modal.removeAttribute("closing");
                    modal.close();
                }, { once: true });
            }
        });
        modal.showModal();
    });
}
function initDropdownFooter() {
    const selectContainers = document.querySelectorAll('.select-container');
    selectContainers.forEach(selectContainer => {
        const selectedOption = selectContainer.querySelector('.selected-option');
        const options = selectContainer.querySelectorAll('.selectopt');
        if (selectedOption && options.length > 0) {
            // Click para abrir/cerrar el dropdown de este contenedor
            selectedOption.addEventListener('click', function () {
                selectContainer.classList.toggle('open');
            });
            // Cambio de opciones solo en este contenedor
            options.forEach(option => {
                option.addEventListener('change', (e) => {
                    var _a;
                    const inputName = option.name;
                    const url = option.value;
                    if (inputName === 'language') {
                        // Obtener el texto del label asociado al input seleccionado
                        const label = selectContainer.querySelector(`label[for="${option.id}"]`);
                        if (label) {
                            selectedOption.textContent = ((_a = label.textContent) === null || _a === void 0 ? void 0 : _a.trim()) || '';
                        }
                        window.location.href = url;
                        selectContainer.classList.remove('open');
                    }
                    else {
                        // Si es el dropdown de sitios, abre el modal y previene que se cambie la selección
                        openRedirectModalForDropdown(url);
                        option.checked = false;
                    }
                });
            });
            // Cerrar el dropdown si se hace click afuera
            document.addEventListener('click', function (event) {
                const target = event.target;
                if (!selectContainer.contains(target)) {
                    selectContainer.classList.remove('open');
                }
            });
        }
    });
}
exports.default = initDropdownFooter;
