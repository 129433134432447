"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.modalFiltersInit = void 0;
const gsap_1 = require("gsap");
const filter_store_1 = require("./filter-store");
const modalFiltersInit = () => {
    const btn = document.querySelector(".modal-filter-pill.tag");
    const modal = document.querySelector(".filter-modal");
    const modalCloseBtn = document.querySelector(".modal-close-button");
    if (!btn || !modal || !modalCloseBtn)
        return;
    const backdrop = modal.querySelector(".backdrop");
    if (!backdrop)
        return;
    let isVisible = false;
    const showTimeline = gsap_1.gsap.timeline({ paused: true }).to(modal, {
        opacity: 1,
        duration: 0.3,
        onStart: () => {
            modal.style.display = "block";
        },
    });
    const hideTimeline = gsap_1.gsap.timeline({ paused: true }).to(modal, {
        opacity: 0,
        duration: 0.3,
        onComplete: () => {
            modal.style.display = "none";
        },
    });
    const toggleModal = () => {
        if (isVisible) {
            hideTimeline.restart();
        }
        else {
            showTimeline.restart();
        }
        isVisible = !isVisible;
    };
    btn.addEventListener("click", toggleModal);
    modalCloseBtn.addEventListener("click", toggleModal);
    backdrop.addEventListener("click", (event) => {
        if (event.target === backdrop) {
            toggleModal();
        }
    });
    const pills = document.querySelectorAll(".filter-small-pill");
    const resetButton = document.querySelector(".btn-primary.white");
    const applyButton = document.querySelector(".btn-secondary.dark");
    const filterButton = document.querySelector(".modal-filter-pill.tag");
    if (!resetButton || !applyButton || !filterButton)
        return;
    // Función para actualizar el estado visual de los pills y el botón de filtro
    const updatePillState = () => {
        const { selectedPills } = filter_store_1.store.getState();
        pills.forEach((pill) => {
            const value = pill.dataset.value;
            if (selectedPills.includes(value || "")) {
                pill.classList.add("--selected");
            }
            else {
                pill.classList.remove("--selected");
            }
        });
        // Actualizar el texto del botón de filtro
        if (selectedPills.length > 0) {
            filterButton.textContent = `Filtrar (${selectedPills.length})`;
        }
        else {
            filterButton.textContent = "Filtrar";
        }
    };
    // Suscribirse a cambios en el store
    filter_store_1.store.subscribe(updatePillState);
    // Agregar eventos a los pills
    pills.forEach((pill) => {
        pill.addEventListener("click", () => {
            const value = pill.dataset.value;
            filter_store_1.store.getState().togglePill(value || "");
        });
    });
    // Agregar eventos a los botones
    resetButton === null || resetButton === void 0 ? void 0 : resetButton.addEventListener("click", () => {
        filter_store_1.store.getState().resetPills();
    });
    applyButton === null || applyButton === void 0 ? void 0 : applyButton.addEventListener("click", () => {
        const { selectedPills } = filter_store_1.store.getState();
        window.location.href = `${window.location.pathname}?c=${selectedPills.join(",")}`;
    });
    // Leer los parámetros de la URL y establecer el estado inicial
    const urlParams = new URLSearchParams(window.location.search);
    const selectedPillsParam = urlParams.get("c");
    if (selectedPillsParam) {
        const selectedPills = selectedPillsParam.split(",");
        filter_store_1.store.getState().setSelectedPills(selectedPills);
    }
    // Actualizar el estado inicial
    updatePillState();
};
exports.modalFiltersInit = modalFiltersInit;
