"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleTextFeatureContentHeight = void 0;
const handleTextFeatureContentHeight = () => {
    const textFeatureElements = document.querySelectorAll('.text-feature');
    if (textFeatureElements.length === 0) {
        return;
    }
    if (window.matchMedia("(min-width: 640px)").matches) {
        textFeatureElements.forEach(textFeatureElement => {
            const titleEl = textFeatureElement.querySelector('.title');
            const contentEl = textFeatureElement.querySelector('.content');
            const titleHeight = titleEl.offsetHeight;
            contentEl.style.height = `calc(100% - ${titleHeight}px`;
        });
    }
};
exports.handleTextFeatureContentHeight = handleTextFeatureContentHeight;
