"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function handleRichTextLinks() {
    const links = document.querySelectorAll('.paragraph.richtext a[href$="#blank"]');
    links.forEach(link => {
        const href = link.getAttribute('href');
        if (href) {
            link.setAttribute('target', '_blank');
            link.setAttribute('href', href.replace('#blank', ''));
        }
    });
}
exports.default = handleRichTextLinks;
