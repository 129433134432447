"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const gsap_1 = require("gsap");
function navDesktopInit() {
    const headerWrapperEl = document.querySelector(".header-wrapper");
    const headerEl = document.querySelector(".header.--above-895");
    // const navDesktopSublistEls = document.querySelectorAll(".nav-desktop-sublist") as NodeListOf<HTMLElement>;
    const menuItemEls = document.querySelectorAll(".menu-item");
    const headerOverlay = document.querySelector(".header-overlay");
    let navDesktopSublistElGridContainer = headerEl.querySelector(".grid-container");
    if (!navDesktopSublistElGridContainer) {
        console.error("navDesktopSublistElGridContainer not found");
        return;
    }
    const headerElHeight = headerEl.offsetHeight;
    headerEl.addEventListener("mouseover", () => {
        headerEl.classList.add("--hovered");
        headerOverlay.classList.add("--active");
    });
    headerEl.addEventListener("mouseleave", () => {
        headerOverlay.classList.remove("--active");
        if (window.pageYOffset <= 100) {
            headerEl.classList.remove("--hovered");
        }
        else {
            return;
        }
    });
    if (window.pageYOffset >= 100) {
        headerEl.classList.add("--hovered");
    }
    navDesktopSublistElGridContainer.addEventListener("mouseleave", () => {
        gsap_1.gsap.to(headerEl, {
            duration: 0.2,
            height: headerElHeight,
        });
    });
    menuItemEls.forEach((menuItemEl) => {
        let menuBtnLinksEls = menuItemEl.querySelectorAll(".menu-btn-link");
        let navDesktopSublistEl = menuItemEl.querySelector(".nav-desktop-sublist");
        if (!navDesktopSublistEl) {
            console.log("navDesktopSublistEl not found");
            return;
        }
        let navDesktopSublistElHeight = navDesktopSublistEl.offsetHeight;
        menuBtnLinksEls.forEach((menuBtnLinkEl) => {
            menuBtnLinkEl.addEventListener("mouseover", () => {
                gsap_1.gsap.to(headerEl, {
                    duration: 0.2,
                    height: headerElHeight + navDesktopSublistElHeight,
                });
                gsap_1.gsap.to(navDesktopSublistEl, {
                    pointerEvents: "all",
                });
                gsap_1.gsap.to(navDesktopSublistEl, {
                    duration: 0.3,
                    opacity: 1,
                });
            });
        });
        menuItemEl.addEventListener("mouseenter", () => {
            menuItemEl.classList.add("--hovered");
            gsap_1.gsap.to(headerEl, {
                duration: 0.2,
                height: headerElHeight + navDesktopSublistElHeight,
            });
        });
        menuItemEl.addEventListener("mouseleave", () => {
            menuItemEl.classList.remove("--hovered");
            gsap_1.gsap.to(headerEl, {
                duration: 0.2,
                height: headerElHeight,
            });
            gsap_1.gsap.to(navDesktopSublistEl, {
                pointerEvents: "none",
            });
            gsap_1.gsap.to(navDesktopSublistEl, {
                duration: 0.5,
                opacity: 0,
            });
        });
    });
    let lastScrollTop = 0;
    window.addEventListener("scroll", () => {
        let currentScroll = window.pageYOffset || document.documentElement.scrollTop;
        if (currentScroll > lastScrollTop) {
            // Scroll Down
            headerWrapperEl.style.top = `${-headerElHeight - 200}px`;
        }
        else {
            // Scroll Up
            headerWrapperEl.style.top = "0";
            headerEl.classList.add("--hovered");
        }
        lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
        if (currentScroll <= 100) {
            headerEl.classList.remove("--hovered");
        }
        if (currentScroll >= 100) {
            headerEl.classList.add("--hovered");
        }
    }, false);
}
exports.default = navDesktopInit;
