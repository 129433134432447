"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
function initSelects(selectsClasses) {
    const selects = document.querySelectorAll(selectsClasses);
    selects.forEach(function (select) {
        select.addEventListener("click", function () {
            this.classList.add("interacted");
        });
    });
}
exports.default = initSelects;
