"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const gsap_1 = require("gsap");
function cookiesConsentInit() {
    const DURATION = { duration: 0.3 };
    const cookieConsentEl = document.querySelector(".cookies");
    const acceptBtn = document.getElementById("acceptAll");
    const acceptBtnCustom = document.getElementById("acceptCustom");
    const rejectBtn = document.getElementById("rejectAll");
    const configureBtn = document.getElementById("configAll");
    const backBtn = document.getElementById("back");
    const csrfMetaEl = document.querySelector("meta[name='csrf-token']");
    const checkboxEl = document.querySelector(".preferences input[type='checkbox']");
    if (!cookieConsentEl)
        return;
    const introEl = cookieConsentEl.querySelector(".intro");
    const preferencesEl = cookieConsentEl.querySelector(".preferences");
    gsap_1.gsap.set(preferencesEl, { autoAlpha: 0, position: "absolute", top: 0, left: 0 });
    gsap_1.gsap.set(backBtn, { pointerEvents: "none" });
    const cookieConsentHideTl = gsap_1.gsap.timeline();
    acceptBtn.addEventListener("click", () => {
        if (csrfMetaEl && cookieConsentEl.dataset.acceptUrl) {
            fetch(cookieConsentEl.dataset.acceptUrl, {
                method: "POST",
                headers: {
                    "X-CSRFToken": csrfMetaEl.content,
                },
            });
        }
        cookieConsentHideTl
            .to(cookieConsentEl, { opacity: 0, DURATION })
            .to(cookieConsentEl, { display: "none", duration: 0 });
    });
    acceptBtnCustom.addEventListener("click", () => {
        if (checkboxEl) {
            if (csrfMetaEl && cookieConsentEl.dataset.acceptUrl && cookieConsentEl.dataset.rejectUrl) {
                const url = checkboxEl.checked
                    ? cookieConsentEl.dataset.acceptUrl
                    : cookieConsentEl.dataset.rejectUrl;
                fetch(url, {
                    method: "POST",
                    headers: {
                        "X-CSRFToken": csrfMetaEl.content,
                    },
                });
            }
        }
        cookieConsentHideTl
            .to(cookieConsentEl, { opacity: 0, DURATION })
            .to(cookieConsentEl, { display: "none", duration: 0 });
    });
    rejectBtn.addEventListener("click", () => {
        if (csrfMetaEl && cookieConsentEl.dataset.rejectUrl) {
            fetch(cookieConsentEl.dataset.rejectUrl, {
                method: "POST",
                headers: {
                    "X-CSRFToken": csrfMetaEl.content,
                },
            });
        }
        cookieConsentHideTl
            .to(cookieConsentEl, { opacity: 0, DURATION })
            .to(cookieConsentEl, { display: "none", duration: 0 });
    });
    configureBtn.addEventListener("click", () => {
        gsap_1.gsap.to(introEl, { position: "absolute", top: 0, left: 0, autoAlpha: 0, maxHeight: "0", duration: 0 });
        gsap_1.gsap.to(preferencesEl, {
            autoAlpha: 1,
            position: "relative",
            maxHeight: "999px",
            DURATION,
            onStart: () => {
                gsap_1.gsap.set(configureBtn, { pointerEvents: "none" });
            },
            onComplete: () => {
                gsap_1.gsap.set(backBtn, { pointerEvents: "all" });
            },
        });
    });
    backBtn.addEventListener("click", () => {
        const tl = gsap_1.gsap.timeline();
        tl.to(introEl, {
            position: "relative",
            autoAlpha: 1,
            maxHeight: "999px",
            duration: 0.5,
            onComplete: () => {
                gsap_1.gsap.set(configureBtn, { pointerEvents: "all" });
            },
        }, 0).to(preferencesEl, {
            autoAlpha: 0,
            position: "absolute",
            top: 0,
            left: 0,
            maxHeight: "0",
            duration: 0,
        }, 0);
    });
}
exports.default = cookiesConsentInit;
