"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.redirectModalInit = exports.popupModalInit = void 0;
function popupModalInit() {
    const modalsEls = document.querySelectorAll(".modal[data-modal='pop-up']");
    modalsEls.forEach((modal) => {
        if (sessionStorage.getItem("popupinfo_modal_show") !== "1") {
            if (modal.hasAttribute("popupinfo")) {
                modal.showModal();
                sessionStorage.setItem("popupinfo_modal_show", "1");
            }
            const closeModal = modal.querySelector(".modal-close-button");
            const contentWrapper = modal.querySelector(".content-wrapper");
            const imageWrapper = modal.querySelector(".image-wrapper");
            const closeEls = [closeModal];
            closeEls.forEach((el) => {
                el.addEventListener("click", () => {
                    modal.setAttribute("closing", "");
                    modal.addEventListener("animationend", () => {
                        modal.removeAttribute("closing");
                        modal.close();
                    }, { once: true });
                });
            });
            // Agregar listener al backdrop
            modal.addEventListener("click", (event) => {
                if (!contentWrapper.contains(event.target) &&
                    !imageWrapper.contains(event.target) &&
                    !closeModal.contains(event.target)) {
                    modal.setAttribute("closing", "");
                    modal.addEventListener("animationend", () => {
                        modal.removeAttribute("closing");
                        modal.close();
                    }, { once: true });
                }
            });
        }
    });
}
exports.popupModalInit = popupModalInit;
function redirectModalInit() {
    var _a;
    const modalsEls = document.querySelectorAll(".modal[data-modal='redirect-modal']");
    const whitelisted_domains = JSON.parse(((_a = document.getElementById("whitelisted_domains")) === null || _a === void 0 ? void 0 : _a.textContent) || "[]");
    document.addEventListener("click", (e) => {
        if (e.target instanceof HTMLAnchorElement && !("externalCheckExempt" in e.target.dataset)) {
            const targetEl = e.target;
            let allow = false;
            for (const element of whitelisted_domains) {
                if (targetEl.href.includes(element)) {
                    allow = true;
                    break;
                }
            }
            if (allow || targetEl.href === "") {
                return;
            }
            else {
                e.preventDefault();
                if (modalsEls.length === 0) {
                    console.error("No redirect modals found");
                    return;
                }
                modalsEls.forEach((modal) => {
                    const modalLinkEls = modal.querySelectorAll(".--modal-link");
                    const closeModal = modal.querySelector(".modal-close-button");
                    const contentWrapper = modal.querySelector(".content-wrapper");
                    const imageWrapper = modal.querySelector(".image-wrapper");
                    const closeEls = [closeModal];
                    modalLinkEls.forEach((btn) => {
                        closeEls.push(btn);
                        btn.href = targetEl.href;
                        btn.target = targetEl.target;
                    });
                    closeEls.forEach((el) => {
                        el.addEventListener("click", () => {
                            modal.setAttribute("closing", "");
                            modal.addEventListener("animationend", () => {
                                modal.removeAttribute("closing");
                                modal.close();
                            }, { once: true });
                        });
                    });
                    // Agregar listener al backdrop
                    modal.addEventListener("click", (event) => {
                        if (!contentWrapper.contains(event.target) &&
                            !imageWrapper.contains(event.target) &&
                            !closeModal.contains(event.target)) {
                            modal.setAttribute("closing", "");
                            modal.addEventListener("animationend", () => {
                                modal.removeAttribute("closing");
                                modal.close();
                            }, { once: true });
                        }
                    });
                    modal.showModal();
                });
            }
        }
    });
}
exports.redirectModalInit = redirectModalInit;
