"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initFilterSelect = exports.initOrderSelect = void 0;
function initOrderSelect() {
    const orderSelect = document.getElementById("orderSelect");
    if (!orderSelect)
        return;
    // Check if urs has order param. If it has, set the option
    const urlParams = new URLSearchParams(window.location.search);
    const order = urlParams.get('o');
    const categories = urlParams.get('c');
    if (order) {
        setSelectOptionFromUrlParam(orderSelect, order);
    }
    orderSelect === null || orderSelect === void 0 ? void 0 : orderSelect.addEventListener("change", (e) => {
        const newOrder = e.target.value;
        window.location.href = `?c=${categories ? categories : ""}&p=1&o=${newOrder}`;
    });
}
exports.initOrderSelect = initOrderSelect;
function initFilterSelect() {
    const filterSelect = document.getElementById("filterSelect");
    if (!filterSelect)
        return;
    setSelectWidth(filterSelect);
    filterSelect === null || filterSelect === void 0 ? void 0 : filterSelect.addEventListener("change", (e) => {
        setSelectWidth(filterSelect);
        const newFilter = e.target.value;
        window.location.href = `?p=1&c=${newFilter}`;
    });
}
exports.initFilterSelect = initFilterSelect;
function setSelectOptionFromUrlParam(select, param) {
    const optionToSelect = select === null || select === void 0 ? void 0 : select.querySelector(`option[value="${param}"]`);
    if (optionToSelect) {
        optionToSelect.selected = true;
    }
}
function setSelectWidth(select) {
    let selectedText = select.options[select.selectedIndex].text;
    select.style.width = 56 + (selectedText.length * 8) + "px";
}
