"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleSelectsInSafari = void 0;
const handleSelectsInSafari = () => {
    let userAgent = navigator.userAgent;
    let isChrome = userAgent.indexOf('Chrome') > -1;
    let isSafari = userAgent.indexOf('Safari') > -1;
    // Chrome tiene la palabra "Safari" en su cadena de agente de usuario, así que necesitamos asegurarnos de que no es Chrome
    // Además, Edge también incluye "Safari" en algunas versiones, por lo que se verifica de manera similar
    let isEdge = userAgent.indexOf('Edg') > -1; // Nota: 'Edg' es usado en lugar de 'Edge' para las versiones más recientes
    if (isSafari && !isChrome && !isEdge) {
        const selectElements = document.querySelectorAll('.custom-filter select, .locale-link .dropdown-footer');
        const caretsElements = document.querySelectorAll('.safari-caret');
        if (selectElements.length === 0) {
            return;
        }
        selectElements.forEach(selectElement => {
            selectElement.style.appearance = 'none';
            selectElement.style.paddingRight = '14px';
        });
        caretsElements.forEach(caretElement => {
            caretElement.style.display = 'block';
        });
    }
};
exports.handleSelectsInSafari = handleSelectsInSafari;
